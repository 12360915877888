import { mapListedProductType } from 'connectors/products/Products';
import { useCategoriesTypeFlagQueryApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useMemo } from 'react';
import { CategoriesByFlagType } from 'types/category';

export function useCategoriesTypeFlag(): CategoriesByFlagType[] | undefined {
    const [{ data, error }] = useCategoriesTypeFlagQueryApi();
    useQueryError(error);
    const { currencyCode } = useDomainConfig();

    return useMemo(() => {
        return data?.categoriesTypeFlag.map((category) => {
            const products = category.productsByCategoryFlag.map((product) => {
                return mapListedProductType(product, currencyCode);
            });

            return {
                category: {
                    name: category.name,
                    slug: category.slug,
                },
                products: products,
            };
        });
    }, [data?.categoriesTypeFlag, currencyCode]);
}
